.contactPageFocusContainerStyle {
  opacity: 1;
  transition: 0.15s ease-in-out;
}
.contactPageBlurContainerStyle {
  opacity: 0;
  transition: 0.15s ease-in-out;
}
.contactInsideContainerStyle {
  max-width: 1440px;
  margin: auto;
  width: 100%;
  padding: 128px 64px 100px 64px;
  display: flex;
  flex-direction: column;
  gap: 100px;
}
.contactPagetopSectionStyle {
  display: flex;
  flex-direction: column;
  gap: 100px;
}
.contactPageTopContainerStyle {
  max-width: 720px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.topContentTitleTextStyle {
  color: var(--black);
  font-family: "NeueHassDisplay-SemiBold", sans-serif;
  font-size: 56px;
  line-height: 60px;
}
.topContentDescTextStyle {
  color: var(--black-opacity-80);
  font-family: "Manrope-Regular", sans-serif;
  font-size: 16px;
  line-height: 24px;
}
.topContainerBtnStyle {
  flex-direction: row;
  align-items: center;
  display: flex;
  gap: 8px;
}
.btnStyle {
  color: var(--orange-dark);
  background-color: transparent;
  border: 1px solid var(--orange-dark);
  transition: 0.4s ease-in-out;
  cursor: pointer;
}
.btnStyle:hover {
  color: var(--orange-light);
  background-color: var(--secondary-EFF1F4);
  border: 1px solid transparent;
  transition: 0.4s ease-in-out;
}
.galleryContainerStyle {
  display: flex;
  flex-wrap: wrap;
  row-gap: 32px;
  align-items: center;
  justify-content: center;
  gap: 16px;
}
.profileCardContainerStyle {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 246px;
  max-height: 360px;
}
.deviderStyle {
  width: 100%;
  border-top: 1px solid var(--black-opacity-20);
}

.sectionContainerStyle {
  margin-top: 16px;
  display: grid;
  grid-template-columns: 40% 50%;
  justify-content: space-between;
}
.sectionRightContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.sectionRightSubContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.sectionTitleStyle {
  font-family: "Manrope-Medium", sans-serif;
  font-size: 18px;
  color: var(--black);
  line-height: 28px;
}
.notARobotContainerStyle {
  width: fit-content;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--secondary-EFF1F4);
  border-radius: 8px;
  opacity: 0.5;
  gap: 16px;
}
.notARobotActiveContainerStyle {
  width: fit-content;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--secondary-EFF1F4);
  border-radius: 8px;
  gap: 16px;
  opacity: 1;
}
.checkBoxStyle {
  display: flex;
  width: 20px;
  height: 20px;
}
.checkBoxImgStyle {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.notARobotTextStyle {
  font-family: "Manrope-Regular", sans-serif;
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;
}
.submitBtnContainerStyle {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  gap: 32px;
}
.submitBtnStyle {
  background-color: var(--orange-dark);
}
.submitBtnStyle:hover {
  background-color: var(--orange-light);
}
.disableBtnStyle {
  background-color: var(--orange-disable);
}
.termsAndConditionsTextStyle {
  font-family: "Manrope-Regular", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: var(--black-opacity-80);
}
.termsAndConditionslinkTextStyle {
  font-family: "Manrope-Medium", sans-serif;
  color: var(--orange-dark);
  cursor: pointer;
}
.officeContainerStyle {
  width: 100%;
  padding: 24px;
  background-color: var(--secondary-EFF1F4);
  border-radius: 16px;
  gap: 8px;
  /* display: flex; */
}
.officeContainerStyle2 {
  width: 100%;
  padding: 24px;
  background-color: var(--secondary-EFF1F4);
  border-radius: 16px;
  gap: 8px;
  cursor: pointer;
}
.officeSubContainerStyle {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.officeTitleContainerstyle {
  flex-direction: row;
  align-items: center;
  gap: 2px;
  display: flex;
  text-decoration: none;
  width: fit-content;
  cursor: pointer;
}
.iconContainerStyle {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.iconStyle {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.mapImgContainerStyle {
  width: 100%;
  height: 224px;
  border-radius: 16px;
  overflow: hidden;
  margin-top: 16px;
  cursor: pointer;
}
.mapImgStyle:hover {
  transform: scale(1.05);
  transition: 0.5s ease-in-out;
}
.mapImgStyle {
  width: 100%;
  height: 100%;
  object-fit: fill;
  transition: 0.5s ease-in-out;
}
.activeOfficeStyle {
  color: var(--orange-dark);
  font-family: "Manrope-Medium", sans-serif;
  line-height: 28px;
  font-size: 18px;
}
.inActiveOfficeStyles {
  color: var(--black);
  font-family: "Manrope-Medium", sans-serif;
  line-height: 28px;
  font-size: 18px;
}
.officeLocationTextStyle {
  color: var(--black-opacity-50);
  font-family: "Manrope-Regular", sans-serif;
  line-height: 24px;
  font-size: 16px;
}
.errorStyle {
  color: var(--error);
  font-family: "Manrope-Regular", sans-serif;
  line-height: 24px;
  font-size: 16px;
}
.sucessStyle {
  color: var(--success);
  font-family: "Manrope-Regular", sans-serif;
  line-height: 24px;
  font-size: 16px;
}
@media screen and (max-width: 1440px) {
  .profileCardContainerStyle {
    max-width: 100%;
    max-height: 100%;
  }
  .topContentDescTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .galleryContainerStyle {
    margin: auto;
    max-width: 996px;
  }
}

@media screen and (max-width: 1280px) {
  .galleryContainerStyle {
    /* grid-template-columns: auto auto auto; */
    /* grid-template-columns: repeat(3, minmax(0, 1fr));
    row-gap: 64px; */
  }

  .sectionContainerStyle {
    grid-template-columns: 25% 70%;
  }
}
@media screen and (max-width: 1109px) {
  .contactInsideContainerStyle {
    padding: 104px 64px 100px 64px;
  }
  .contactPagetopSectionStyle {
    gap: 80px;
  }
  .topContentTitleTextStyle {
    font-size: 48px;
    line-height: 52px;
  }
  .galleryContainerStyle {
    /* grid-template-columns: auto auto; */
    /* grid-template-columns: repeat(2, minmax(0, 1fr)); */
    row-gap: 48px;
    justify-content: center;
  }
  .cardContainerStyle {
    width: 100%;
  }
  .sectionContainerStyle {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
}
@media screen and (max-width: 867px) {
  .contactInsideContainerStyle {
    padding: 104px 32px 100px 32px;
  }
  .contactPagetopSectionStyle {
    gap: 64px;
  }
  .contactPageTopContainerStyle {
    gap: 24px;
  }
  .topContainerBtnStyle {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media screen and (max-width: 620px) {
  .galleryContainerStyle {
    /* grid-template-columns: auto;
    justify-content: space-evenly; */
    gap: 16px;
  }
}
@media screen and (max-width: 485px) {
  .contactInsideContainerStyle {
    padding: 96px 24px 96px 24px;
  }
  .contactPagetopSectionStyle {
    gap: 48px;
  }
  .topContentTitleTextStyle {
    font-size: 40px;
    line-height: 44px;
  }
  .btnStyle {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .galleryContainerStyle {
    justify-content: start;
  }

  .submitBtnContainerStyle {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 16px;
  }
  .submitBtnStyle {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .termsAndConditionsTextStyle {
    text-align: center;
  }
}
