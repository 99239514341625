.inputStyle {
  height: 48px;
  width: 100%;
  padding: 16px;
  font-family: "Manrope-Regular";
  font-size: 16px;
  color: var(--black);
  outline: none;
  border: 1px solid var(--black-opacity-20);
  border-radius: 8px;
}
