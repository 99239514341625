.jobPageFocusContainerStyle {
  opacity: 1;
  transition: 0.15s ease-in-out;
}
.jobPageBlurContainerStyle {
  opacity: 0;
  transition: 0.15s ease-in-out;
}

.jobPageInsideContainerStyle {
  max-width: 1440px;
  margin: auto;
  width: 100%;
  padding: 128px 64px 100px 64px;
  display: flex;
  flex-direction: column;
  gap: 100px;
}
.jonPositionHeaderContainerStyle {
  max-width: 720px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.jonPositionHeaderTextStyle {
  font-family: "NeueHassDisplay-SemiBold", sans-serif;
  font-size: 40px;
  line-height: 44px;
  color: var(--black);
}
.jonPositionHeaderTextOrangeStyle {
  font-family: "NeueHassDisplay-SemiBold", sans-serif;
  font-size: 40px;
  line-height: 44px;
  color: var(--orange-dark);
}
.jonPositionHeaderTextOrangeStyle::last-word {
  font-family: "NeueHassDisplay-SemiBold", sans-serif;
  font-size: 40px;
  line-height: 44px;
  color: var(--black);
}
.jonPositionHeaderContentTextStyle {
  font-family: "Manrope-Regular", sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: var(--black-opacity-80);
}
.jobOtionsMainContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-top: 1px solid var(--black-opacity-20);
  padding-top: 16px;
}
.jobPositionOuterContainerStyle {
  padding-bottom: 16px;
  border-bottom: 1px solid var(--black-opacity-20);
}
.jobOtionsContainerStyle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
}
.jobOtions2ContainerStyle {
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: space-around;
  cursor: pointer;
}
.jobOptionsHeaderTextStyle {
  font-family: "Manrope-Medium", sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: var(--black);
}
.contentOpenStyle {
  opacity: 1;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}
.contentCloseStyle {
  opacity: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
}

.jobPageBottomCardStyle {
  background-color: var(--secondary-EFF1F4);
  padding: 32px;
  max-width: 720px;
  margin: auto;
  border-radius: 16px;
}
.jobPageBottomCardTextStyle {
  font-family: "Manrope-Regular", sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: var(--black);
}
.jobPageBottomCardOrangeTextStyle {
  font-family: "Manrope-Regular", sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: var(--black);
}
.imgWrapperStyle {
  width: 24px;
  height: 24px;
  justify-self: flex-end;
}
.imgStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media screen and (max-width: 1440px) {
  .jonPositionHeaderContentTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .jobOptionsContentTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .jobPageBottomCardTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .jobPageBottomCardOrangeTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
}
@media screen and (max-width: 1080px) {
  .jobPageInsideContainerStyle {
    padding: 104px 64px 80px 64px;
    gap: 80px;
  }
  .jobOtions2ContainerStyle {
    max-width: 568px;
    margin-left: auto;
    display: flex;
    gap: 16px;
    flex-direction: column;
  }
}
@media screen and (max-width: 867px) {
  .jobPageInsideContainerStyle {
    padding: 104px 32px 64px 32px;
    gap: 64px;
  }
  .jobPageBottomCardStyle {
    padding: 24px;
  }
  .jobOtions2ContainerStyle {
    margin: auto;
  }
}
@media screen and (max-width: 485px) {
  .jobPageInsideContainerStyle {
    padding: 96px 24px 48px 24px;
    gap: 48px;
  }
  .jonPositionHeaderContainerStyle {
    gap: 24px;
  }
  .jonPositionHeaderTextStyle {
    font-size: 32px;
    line-height: 36px;
  }
  .jonPositionHeaderTextOrangeStyle {
    font-size: 32px;
    line-height: 36px;
  }
  /* .jobOtionsContainerStyle {
    display: grid;
    grid-template-columns: 70% 20%;
    justify-content: space-around;
  } */
}
