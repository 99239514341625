.footerStyle {
  opacity: 1;
  transition: 0.5s ease-in-out;
}
.footerStyle2 {
  opacity: 0;
  transition: 0.5s ease-in-out;
}
.footerMainBlueContainerStyle {
  background-color: var(--blue-primary);
}
.footerMainWhiteContainerStyle {
  background-color: var(--white);
}
.footerInsideContainerStyle {
  max-width: 1440px;
  margin: auto;
  padding: 64px 64px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 64px;
}
.footerItemsContainerStyle {
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto auto;
  align-items: flex-start;
  justify-content: space-between;
}
.footerItemWrapperStyle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}
.footerItemHeaderWhiteTextStyle {
  font-family: "Manrope-Medium", sans-serif;
  font-size: 17px;
  line-height: 26px;
  color: var(--white);
}
.footerItemHeaderBlueTextStyle {
  font-family: "Manrope-Medium", sans-serif;
  font-size: 17px;
  line-height: 26px;
  color: var(--blue-primary);
}
.footerItemContentWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.footerItemContentBlueTextStyle {
  font-family: "Manrope-Regular", sans-serif;
  font-size: 17px;
  line-height: 26px;
  color: var(--blue-secondary);
  cursor: pointer;
}
.footerItemContentBlueTextStyle:hover {
  color: var(--orange-dark);
}
.footerItemContentWhiteTextStyle {
  font-family: "Manrope-Regular", sans-serif;
  font-size: 17px;
  line-height: 26px;
  color: var(--white-opacity-50);
  cursor: pointer;
}
.footerItemContentWhiteTextStyle:hover {
  color: var(--orange-dark);
}
.copyrightsContainerStyle {
  text-align: center;
}
.copyRightsWhiteTextStyle {
  font-family: "Manrope-Regular", sans-serif;
  font-size: 17px;
  line-height: 26px;
  color: var(--white);
}
.copyRightsBlueTextStyle {
  font-family: "Manrope-Regular", sans-serif;
  font-size: 17px;
  line-height: 26px;
  color: var(--blue-primary);
}
@media screen and (max-width: 1440px) {
  .footerItemHeaderWhiteTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .footerItemHeaderBlueTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .footerItemContentBlueTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .footerItemContentWhiteTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .copyRightsWhiteTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .copyRightsBlueTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
}
@media screen and (max-width: 1080px) {
  .footerInsideContainerStyle {
    padding: 48px 64px 32px 64px;
    gap: 48px;
  }
}
@media screen and (max-width: 867px) {
  .footerInsideContainerStyle {
    padding: 48px 32px 32px 32px;
  }
  .footerItemsContainerStyle {
    grid-template-columns: auto auto;
    grid-row-gap: 32px;
  }
}
@media screen and (max-width: 485px) {
  .footerInsideContainerStyle {
    padding: 48px 24px 24px 24px;
    gap: 48px;
  }
  .footerItemsContainerStyle {
    grid-template-columns: auto;
    grid-row-gap: 24px;
  }
  .footerItemWrapperStyle {
    gap: 4px;
  }
}
@media screen and (max-width: 360px) {
}
