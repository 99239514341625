.jobDetailsContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.inputMainContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.inputContainerStyle2 {
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.inputContainerStyle {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: center;
}
.inputInsideContainerStyle{
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}
.langInputContainerStyle{
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  width: 100%;
}
.buttonStyle {
  width: fit-content;
}
.listContainerStyle {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.listInsideContainerStyle {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
}
.listInputContainerStyle{
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  width: 100%;
}
.listLabelContainerStyle{
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}
.popoverStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
}
.inputStyle {
  border: none;
}
.closeIconStyle {
  min-width: 24px;
}
.imageContainerStyle {
  display: flex;
  flex-direction: row;
  gap: 24px;
  width: 100%;
  align-items: center;
}
.imageWrapperStyle {
  max-width: 700px;
  width: 100%;
  height: 400px;
  border-radius: 16px;
  overflow: hidden;
}
.imgStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.textPopupStyle {
  cursor: pointer;
}
.successStyle {
  color: green;
}
.errStyle {
  color: red;
}
