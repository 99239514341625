.ourTeamPageFocusContainerStyle {
  opacity: 1;
  transition: 0.15s ease-in-out;
}
.ourTeamPageBlurContainerStyle {
  opacity: 0;
  transition: 0.15s ease-in-out;
}

.backgroundColorStyle {
  background-color: var(--blue-primary);
}
.outTeamInsideContainerStyle {
  max-width: 1440px;
  margin: auto;
  width: 100%;
  padding: 128px 64px 100px 64px;
  display: flex;
  flex-direction: column;
  gap: 64px;
}
.headerTextContainerStyle {
  max-width: 720px;
  width: 720px;
  margin: auto;
}
.titleTextStyle {
  font-family: "NeueHassDisplay-SemiBold";
  font-size: 56px;
  color: var(--white);
  line-height: 60px;
}
.deviderStyle {
  width: 100%;
  border-top: 1px solid var(--white-opacity-20);
}
.teamProfileContainerStyle {
  flex-direction: column;
  display: flex;
  gap: 32px;
}
.profileContainerStyle {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  row-gap: 32px;
  align-items: center;
  justify-content: center;
}
.profileCardContainerStyle {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.profileTitleStyle {
  color: var(--white);
}
.profileDescStyle {
  color: var(--white-opacity-50);
}
@media screen and (max-width: 1440px) {
}
/* @media screen and (max-width: 1280px) {
  .profileContainerStyle {
    grid-template-columns: auto auto auto;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-auto-rows: max-content;
  }
} */
@media screen and (max-width: 1024px) {
  /* .profileContainerStyle {
    grid-template-columns: auto auto auto;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    justify-content: space-evenly;
    grid-auto-rows: max-content;
  } */
}
@media screen and (max-width: 985px) {
  .outTeamInsideContainerStyle {
    padding: 104px 64px 104px 64px;
  }
  .titleTextStyle {
    font-size: 48px;
    line-height: 52px;
  }
}
@media screen and (max-width: 867px) {
  .headerTextContainerStyle {
    max-width: 496px;
    width: 496px;
  }
  .outTeamInsideContainerStyle {
    padding: 104px 32px 104px 32px;
    gap: 48px;
  }
  .profileTitleStyle {
    color: var(--orange-dark);
  }
}

@media screen and (max-width: 600px) {
  .profileContainerStyle {
    justify-content: flex-start;
    row-gap: 16px;
  }
}
@media screen and (max-width: 485px) {
  .outTeamInsideContainerStyle {
    padding: 96px 24px 96px 24px;
    gap: 32px;
  }
  .headerTextContainerStyle {
    max-width: 336px;
    width: 100%;
  }
  .titleTextStyle {
    font-size: 40px;
    line-height: 44px;
  }

  .profileTitleStyle {
    color: var(--orange-dark);
  }
}
