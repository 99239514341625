.homePageFocusContainerStyle {
  opacity: 1;
  transition: 0.15s ease-in-out;
  height: 100%;
}
.homePageBlurContainerStyle {
  opacity: 0;
  transition: 0.15s ease-in-out;
  height: 100%;
}
.bgVideoStyle {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  object-fit: cover;
  height: 100%;
  z-index: -6;
}
.homePageMainContainerStyle {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  transition: 0.4s ease;
  transform: translateY(0%);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  /* background-image: url("../../assets/video/homeBgDesktop\ \(1\).gif"); */
  z-index: -5;
  display: flex;
  align-items: flex-end;
}
.homePageMainDownContainerStyle {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  transition: 0.55s ease;
  transform: translateY(100vh);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  /* background-image: url("../../assets/video/homeBgDesktop\ \(1\).gif"); */
  z-index: -5;
  display: flex;
  align-items: flex-end;
}
.homePageMainInsideContainerStyle {
  max-width: 1440px;
  width: 100%;
  height: 100%;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.heroHeaderContainerStyle {
  width: 100%;
  min-height: -webkit-fill-available;
  height: 100%;
  padding: 64px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}
.heroHeaderTextContainerStyle {
  max-width: 720px;
}
.heroHeaderTextStyle {
  font-family: "NeueHassDisplay-SemiBold", sans-serif;
  font-size: 80px;
  line-height: 84px;
  color: var(--white);
}
.btnHoverStyles:hover {
  background-color: var(--white);
  color: var(--blue-primary);
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}
.arrowButtonStyle {
  opacity: 0;
  width: 0px;
  height: 24px;
  transition: 0.3s ease-in-out;
}
.btnHoverStyles:hover .arrowButtonStyle {
  width: 24px;
  height: 24px;
  opacity: 1;
  transition: 0.3s ease-in-out;
}
.cookieOuterContainerStyle {
  position: absolute;
  bottom: 0px;
  left: 0px;
  top: 0px;
  right: 0px;
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: transparent;
}
.cookieContainerStyle {
  width: 100%;
  position: absolute;
  bottom: 0px;
  background-color: var(--white);
}
.cookieInsideContainerStyle {
  max-width: 1440px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  padding: 32px 64px;
}
.cookieTextStyle {
  font-family: "Manrope-Regular", sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: var(--blue-primary);
  text-align: center;
}
.linkTextStyle {
  font-family: "Manrope-Medium", sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: var(--orange-dark);
  text-decoration: underline;
  cursor: pointer;
}
.cookieBtnContainerStyle {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
}
.cookieBtnSettingStyles {
  background-color: var(--secondary-EEEEEE) !important;
  color: var(--black) !important;
  cursor: pointer;
}
.cookieBtnAgreeStyles {
  background-color: var(--blue-primary) !important;
  color: var(--white) !important;
  cursor: pointer;
}
@media screen and (max-width: 1440px) {
  .cookieTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .linkTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
}
@media screen and (max-width: 1080px) {
  .heroHeaderTextStyle {
    font-size: 64px;
    line-height: 68px;
  }
  .heroHeaderTextContainerStyle {
    max-width: 520px;
  }
}
@media screen and (max-width: 867px) {
  .heroHeaderContainerStyle {
    padding: 48px 32px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: flex-end;
    align-items: flex-start;
  }
  .heroHeaderTextStyle {
    font-size: 48px;
    line-height: 52px;
  }
  .cookieInsideContainerStyle {
    gap: 24px;
    padding: 24px 32px;
  }
}
@media screen and (max-device-width: 1080px) and (orientation: landscape) {
  .heroHeaderContainerStyle {
    padding: 24px 32px;
  }

  .cookieContainerStyle {
    bottom: 0px;
  }
}
@media screen and (max-width: 485px) {
  .bgVideoStyle {
    display: none;
  }
  .homePageMainContainerStyle {
    background-image: url("../../assets/video/bgmobile.gif");
    height: 100%;
  }
  .homePageMainDownContainerStyle {
    background-image: url("../../assets/video/bgmobile.gif");
    height: 100%;
  }
  .homePageMainInsideContainerStyle {
    min-height: -webkit-fill-available;
    height: 100%;
  }
  .heroHeaderContainerStyle {
    padding: 48px 24px;
  }
  .btnHoverStyles {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .cookieInsideContainerStyle {
    gap: 24px;
    padding: 24px;
  }
}
@media screen and (max-width: 360px) {
}
