.commonMainContainerStyle {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  transition: 0.4s ease;
  transform: translateY(0%);
  z-index: -5;
}
.commonMainDownContainerStyle {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  transition: 0.4s ease;
  transform: translateY(100vh);
  z-index: -5;
}
