.inputContainerStyle {
  max-width: 600px;
  margin: auto;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 48px;
  align-items: center;
  justify-content: center;
  padding: 100px 64px;
}
.inputWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}
.headerTextStyle {
  font-family: "NeueHassDisplay-SemiBold", sans-serif;
  font-size: 44px;
  line-height: 48px;
  color: var(--black);
}
.buttonStyles {
  width: 100%;
  align-items: center;
  justify-content: center;
}
.errorTextStyle {
  font-family: "Manrope Medium", sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: var(--error);
}
@media screen and (max-width: 485px) {
  .inputContainerStyle {
    gap: 32px;
    padding: 100px 24px;
  }
}
