.cardContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 100%;
  height: 442px;
  width: 100%;
  max-width: 316px;
  transition: 0.4s ease-in-out;
  overflow: hidden;
  transform-origin: top;
  cursor: pointer;
}
.imgContaineStyle {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  transform-origin: center;
  transition: 0.4s ease-in-out;
  overflow: hidden;
}
.imgStyle {
  width: 100%;
  height: 100%;
  object-fit: unset;
  transform: scaleX(1.05);
  transition: 0.4s ease-in-out;
}

.titleContainer {
  flex-direction: row;
  display: flex;
  align-items: center;
}
.arrowImgConatinerStyle {
  width: 24px;
  height: 24px;
  transition: all 0.4s ease-in-out;
  opacity: 0;
  visibility: hidden;
}
.arrowimgstyle {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.cardTitleStyle {
  font-family: "Manrope-Medium", sans-serif;
  font-size: 18px;
  color: var(--blue-primary);
  line-height: 28px;
  transition: 0.4s ease-in-out;
}
.cardDescStyle {
  color: var(--transparent-blue);
  font-family: "Manrope-Regular", sans-serif;
  font-size: 16px;
  line-height: 24px;
  min-height: 48px;
}
.cardContainerStyle:hover .imgContaineStyle {
  transition: 0.4s ease-in-out;
  transform: scaleX(0.995);
}
.cardContainerStyle:hover {
  transform-origin: top;
  transform: scaleY(0.96);
  transition: 0.4s ease-in-out;
  max-width: 316px;
}
.cardContainerStyle:hover .arrowImgConatinerStyle {
  width: 24px;
  height: 24px;
  transition: all 0.4s ease-in-out;
  opacity: 1;
  visibility: visible;
}
.cardContainerStyle:hover .imgStyle {
  transition: 0.4s ease-in-out;
  transform: scale(1);
  overflow: hidden;
  transform-origin: top;
  width: 100%;
}
.cardContainerStyle:hover .cardTitleStyle {
  color: var(--orange-dark);
  transition: 0.4s ease-in-out;
}
@media screen and (max-width: 1280px) {
  .imgContaineStyle {
    max-width: 100%;
  }
}

@media screen and (max-width: 867px) {
  .imgContaineStyle {
    height: 100%;
  }
  .arrowImgConatinerStyle {
    display: block;
  }
  .cardTitleStyle {
    color: var(--orange-dark);
  }
}
@media screen and (max-width: 600px) {
  .cardContainerStyle {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
  }
  .cardTitleStyle {
    color: var(--orange-dark);
  }
  .imgContaineStyle {
    min-width: 128px;
    min-height: 128px;
    width: 128px;
    height: 128px;
  }
  .imgStyle {
    object-fit: unset;
    transform: scale(1);
  }
  .cardContainerStyle:hover .imgContaineStyle {
    height: 128px;
  }
  .arrowImgConatinerStyle {
    display: none;
  }
}
