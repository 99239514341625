.contentContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.listStyle {
  list-style-position: inside;
}
.jonPositionHeaderContentTextStyle {
  font-family: "Manrope-Regular", sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: var(--black-opacity-80);
}
.jonPositionHeaderLinkTextStyle {
  font-family: "Manrope-Regular", sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: var(--orange-dark);
}
.heroImageWrapperStyle {
  width: 100%;
  height: 420px;
}
.heroImgStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
}
@media screen and (max-width: 1440px) {
  .jonPositionHeaderContentTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .jonPositionHeaderLinkTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
}
@media screen and (max-width: 1080px) {
  .heroImageWrapperStyle {
    height: 360px;
  }
}
@media screen and (max-width: 867px) {
  .heroImageWrapperStyle {
    height: 260px;
  }
}
@media screen and (max-width: 485px) {
  .heroImageWrapperStyle {
    height: 200px;
  }
}
