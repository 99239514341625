.imgStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.logoImgWrapperStyle {
  width: 152px;
  cursor: pointer;
}
.topLogoContainerStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.modalContainerStyle {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: var(--blue-primary);
  padding: 20px 64px 100px 64px;
  display: flex;
  flex-direction: column;
  gap: 84px;
}
.subcaontainerStyle {
  display: flex;
  flex-direction: row;
  gap: 64px;
  max-width: 1440px;
  margin: auto;
}
.imgContainerStyle {
  max-width: 528px;
  max-height: 528px;
  width: 100%;
  height: 100%;
}
.imageStyle {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 16px;
}
.detailsContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 720px;
}
.titleContainerStyle {
  display: flex;
  flex-direction: column;
}
.modalTitleTextStyle {
  font-family: "NeueHassDisplay-SemiBold", sans-serif;
  font-size: 56px;
  line-height: 60px;
  color: var(--white);
}
.subTitleStyle {
  font-family: "Manrope-Medium", sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: var(--white);
}
.modalTitleDesTextStyle {
  font-family: "Manrope-Regular", sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: var(--white-opacity-80);
}
.otherDetailsStyle {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.otherDetailsSubStyle {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.listTextStyle {
  display: flex;
  flex-direction: column;
}
.subTitleDescStyle {
  font-family: "Manrope-Regular", sans-serif;
  font-size: 18px;
  color: var(--orange-dark);
  line-height: 28px;
}
.subTitleDescStyle2 {
  font-family: "Manrope-Regular", sans-serif;
  font-size: 18px;
  color: var(--white-opacity-80);
  line-height: 28px;
}
.subTitleDescTextStyle {
  height: 28px;
}
.btnContainerStyle {
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
}
.btnStyle {
  color: var(--orange-dark);
  background-color: transparent;
  border: 1px solid var(--orange-dark);
  transition: 0.4s ease-in-out;
}
.btnStyle:hover {
  color: var(--orange-light);
  background-color: var(--blue-hover);
  border: 1px solid transparent;
  transition: 0.4s ease-in-out;
}
.btnStyle2 {
  color: var(--white);
  background-color: var(--orange-light);
  border: 1px solid transparent;
  transition: 0.4s ease-in-out;
  font-family: "Manrope-Medium", sans-serif;
  font-size: 16px;
  line-height: 24px;
  border-radius: 26px;
  padding: 12px 32px;
  text-align: center;
  width: fit-content;
}
.closeBtnStyle {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}
.closeBtnTextStyle {
  font-family: "Manrope-Medium", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: var(--white);
}

@media screen and (max-width: 1440px) {
  .modalTitleDesTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .subTitleDescStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .subTitleDescStyle2 {
    font-size: 16px;
    line-height: 24px;
  }
}
@media screen and (max-width: 1280px) {
  .imgContainerStyle {
    max-width: 360px;
    max-height: 360px;
    width: 100%;
    height: 100%;
  }
  .detailsContainerStyle {
    max-width: 434px;
  }
}
@media screen and (max-width: 1080px) {
  .detailsContainerStyle {
    max-width: 356px;
  }
}
@media screen and (max-width: 985px) {
  .modalContainerStyle {
    gap: 60px;
    padding: 20px 64px 80px;
  }
  .modalTitleTextStyle {
    font-size: 48px;
    line-height: 52px;
  }
  .imgContainerStyle {
    max-width: 320px;
    max-height: 320px;
    width: 100%;
    height: 100%;
  }
}
@media screen and (max-width: 867px) {
  .modalContainerStyle {
    padding: 20px 32px 64px 32px;
  }
  .modalContainerStyle {
    height: 100%;
  }
  .subcaontainerStyle {
    flex-direction: column;
    gap: 48px;
  }
  .imgContainerStyle {
    max-width: 386px;
    max-height: 386px;
    width: 100%;
    height: 100%;
  }
  .btnContainerStyle {
    flex-direction: column;
    max-width: 100%;
  }
  .btnStyle {
    width: fit-content;
  }
  .detailsContainerStyle {
    max-width: 496px;
  }
}
@media screen and (max-width: 485px) {
  .modalContainerStyle {
    gap: 52px;
    padding: 20px 24px 48px;
  }
  .imgContainerStyle {
    max-width: 366px;
    max-height: 360px;
    width: 100%;
    height: 100%;
  }
  .modalTitleTextStyle {
    font-size: 40px;
    line-height: 44px;
  }
  .otherDetailsStyle {
    gap: 24px;
  }
  .btnStyle {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .btnStyle2 {
    width: 100%;
  }
}
@media screen and (max-width: 350px) {
  .modalTitleTextStyle {
    font-size: 32px;
    line-height: 36px;
  }
  .imgContainerStyle {
    max-width: 366px;
    max-height: 320px;
    width: 100%;
    height: 100%;
  }
}
