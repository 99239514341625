.navBarStyle {
  position: relative;
  opacity: 0;
  transition: 0.15s ease-in-out;
  height: 100%;
}
.navBarStyle2 {
  position: relative;
  opacity: 1;
  transition: 0.15s ease-in-out;
  height: 100%;
}
.imgStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* navbar without modal styles start  */
.navMainContainerStyle {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 64px;
  transition: 0.4s ease;
  transform: translateY(0%);
}
.navMainWhiteLogoContainerStyle {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 64px;
  transition: 0.4s ease;
  transform: translateY(0%);
  background-color: var(--blue-primary);
}
.navMainCloseContainerStyle {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 64px;
  transform: translateY(200vh);
  transition: 0.4s ease;
}
.navBlueMainContainerStyle {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 64px;
  transition: 0.4s ease;
  transform: translateY(0%);
  background-color: var(--white);
}
.logoImgWrapperStyle {
  width: 152px;
  /* height: 16px; */
  cursor: pointer;
}
.logoImgWrapper2Style {
  width: 152px;
  /* height: 16px; */
  /* cursor: pointer; */
}
.navRightContainerStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 21px;
}
.navItemLangWrapperStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}
.navLangTextWhiteStyle {
  font-family: "Manrope-Medium", sans-serif;
  font-size: 18px;
  line-height: 24px;
  color: var(--white);
}
.navLangTextBlueStyle {
  font-family: "Manrope-Medium", sans-serif;
  font-size: 18px;
  line-height: 24px;
  color: var(--blue-primary);
}
.navlangImgWrapperStyle {
  width: 24px;
  height: 24px;
}
.navItemLangWrapperStyle:hover .navLangTextWhiteStyle {
  color: var(--orange-dark);
}
.navItemLangWrapperStyle:hover .navLangTextBlueStyle {
  color: var(--orange-dark);
}
.globeBlueImgStyle {
  fill: var(--blue-primary);
}
.globeWhiteImgStyle {
  fill: var(--white);
}
.navItemLangWrapperStyle:hover .globeBlueImgStyle {
  fill: var(--orange-dark);
}
.navItemLangWrapperStyle:hover .globeWhiteImgStyle {
  fill: var(--orange-dark);
}
.navItemMenuWrapperStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}

.navMenuTextWhiteStyle {
  font-family: "Manrope-Medium", sans-serif;
  font-size: 18px;
  line-height: 24px;
  color: var(--white);
}
.navMenuTextBlueStyle {
  font-family: "Manrope-Medium", sans-serif;
  font-size: 18px;
  line-height: 24px;
  color: var(--blue-primary);
}
.navMenuImgWrapperStyle {
  width: 24px;
  height: 24px;
}
.navItemMenuWrapperStyle:hover .navMenuTextWhiteStyle {
  color: var(--orange-dark);
}
.navItemMenuWrapperStyle:hover .navMenuTextBlueStyle {
  color: var(--orange-dark);
}
.downArrBlueImgStyle {
  fill: var(--blue-primary);
}
.downArrWhiteImgStyle {
  fill: var(--white);
}
.navItemMenuWrapperStyle:hover .downArrBlueImgStyle {
  fill: var(--orange-dark);
}
.navItemMenuWrapperStyle:hover .downArrWhiteImgStyle {
  fill: var(--orange-dark);
}

/* navbar without modal styles end  */
/* navbar modal styles start  */
.navBarMenuContainerStyle {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background-color: var(--blue-primary);
  transition: 0.4s ease;
  transform: translateY(0%);
  z-index: 5;
}
.navBarMenuCloseContainerStyle {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background-color: var(--blue-primary);
  transform: translateY(-100vh);
  transition: 0.48s ease;
  z-index: 5;
}
.navOptionsMainContainerStyle {
  height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 64px;
}
.navMenuCloseTextWhiteStyle {
  font-family: "Manrope-Medium", sans-serif;
  font-size: 18px;
  line-height: 24px;
  color: var(--white);
}
.upArrowStyles {
  fill: var(--white);
}
.navBarOptionsContainerStyle {
  max-width: 1440px;
  margin: auto;
  height: calc(100% - 64px);
  padding: 0 196px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.navBarOptionsWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.navOptionHoverContainerStyle {
  width: fit-content;
  display: flex;
  flex-direction: row;
  gap: 0px;
  transition: 0.4s ease;
  cursor: pointer;
}
.navOptionNotContainerStyle {
  width: fit-content;
  display: flex;
  flex-direction: row;
  gap: 0px;
  cursor: pointer;
}
.navOptionHoverArrImgWrapperStyle {
  display: none;
  transition: 0.4s ease;
}
.navOptionHoverContainerStyle:hover {
  transition: 0.4s ease;
  transform: translateX(16px);
}
.navOptionHoverContainerStyle:hover .navOptionHoverArrImgWrapperStyle {
  display: block;
  width: 60px;
  height: 60px;
  transition: 0.4s ease;
}

.navBarOptionTextStyle {
  font-family: "NeueHassDisplay-SemiBold", sans-serif;
  font-size: 56px;
  line-height: 60px;
  color: var(--white);
  transition: 0.4s ease;
}
.navOptionSelectedStyle {
  font-family: "NeueHassDisplay-SemiBold", sans-serif;
  font-size: 56px;
  line-height: 60px;
  color: var(--orange-dark);
}
.navOptionHoverContainerStyle:hover .navBarOptionTextStyle {
  color: var(--orange-dark);
  transition: 0.4s ease;
}
.navBarRightOptionsContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.navBarPolicyContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.navBarPolicyTextStyle {
  cursor: pointer;
  font-family: "Manrope-Regular", sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: var(--white);
}
.navBarPolicyTextStyle:hover {
  color: var(--orange-dark);
}
.navBarOptionsLanguageContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.navBarOptionsLangWrapperStyle {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  cursor: pointer;
}
.languageTextStyle {
  font-family: "Manrope-Medium", sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: var(--white);
}
.languageSelectedTextStyle {
  font-family: "Manrope-Medium", sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: var(--orange-dark);
}
.globeColor {
  fill: var(--orange-dark);
}
/* navbar modal styles end  */
/* popover Styles  */
.popoverContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
}
.popoverLangContainerStyle {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  cursor: pointer;
}
.imageWrapperStyle {
  width: 20px;
  height: 20px;
}
.popoverTextSTyle {
  font-family: "Manrope-Medium", sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: var(--black-opacity-50);
}
.popOverSelectTextStyle {
  font-family: "Manrope-Medium", sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: var(--black);
}
/* popover styles end  */
@media screen and (max-width: 1440px) {
  /* navbar without modal styles start  */
  .navLangTextWhiteStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .navLangTextBlueStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .navMenuTextWhiteStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .navMenuTextBlueStyle {
    font-size: 16px;
    line-height: 24px;
  }
  /* navbar without modal styles end  */
  /* navbar modal styles start  */
  .navMenuCloseTextWhiteStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .navBarPolicyTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .languageTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .languageSelectedTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
  /* navbar modal styles end  */
  .popoverTextSTyle {
    font-size: 16px;
    line-height: 24px;
  }
  .popOverSelectTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
}
@media screen and (max-width: 1365px) {
  /* navbar without modal styles start  */

  /* navbar without modal styles end  */
  /* navbar modal styles start  */
  .navBarOptionsContainerStyle {
    padding: 0 128px;
  }
  .navBarOptionTextStyle {
    font-size: 48px;
    line-height: 52px;
  }
  .navOptionSelectedStyle {
    font-size: 48px;
    line-height: 52px;
  }
  .navOptionHoverContainerStyle:hover .navOptionHoverArrImgWrapperStyle {
    width: 52px;
    height: 52px;
  }
  /* navbar modal styles end  */
}
@media screen and (max-width: 1119px) {
  /* navbar without modal styles start  */

  /* navbar without modal styles end  */
  /* navbar modal styles start  */
  .navBarOptionsContainerStyle {
    padding: 0 64px;
  }
  /* navbar modal styles end  */
}
@media screen and (max-width: 867px) {
  /* navbar without modal styles start  */
  .navMainContainerStyle {
    padding: 0 32px;
  }
  .navBlueMainContainerStyle {
    padding: 0 32px;
  }
  .navMainWhiteLogoContainerStyle {
    padding: 0 32px;
  }
  .navRightContainerStyle {
    gap: 16px;
  }
  .navLangTextWhiteStyle {
    display: none;
  }
  .navLangTextBlueStyle {
    display: none;
  }
  /* navbar without modal styles end  */
  /* navbar modal styles start  */
  .navOptionsMainContainerStyle {
    padding: 0 32px;
  }
  .navBarOptionsContainerStyle {
    width: 100%;
    padding: 0 32px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: flex-start;
    justify-content: center;
  }
  .navBarOptionTextStyle {
    font-size: 40px;
    line-height: 44px;
  }
  .navOptionSelectedStyle {
    font-size: 40px;
    line-height: 44px;
  }
  .navBarRightOptionsContainerStyle {
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .navOptionHoverContainerStyle:hover .navOptionHoverArrImgWrapperStyle {
    width: 44px;
    height: 44px;
  }
  .navMenuCloseTextWhiteStyle {
    display: none;
  }
  .navBarMenuCloseContainerStyle {
    transform: translateY(-100%);
  }
  /* navbar modal styles end  */
}
@media screen and (max-device-width: 1080px) and (orientation: landscape) {
  .navOptionsMainContainerStyle {
    height: 48px;
  }
  .navBarOptionsContainerStyle {
    width: 100%;
    padding: 0 32px;
    display: flex;
    flex-direction: row;
    gap: 32px;
    align-items: center;
    justify-content: space-between;
  }
  .navBarOptionTextStyle {
    font-size: 20px;
    line-height: 24px;
    white-space: nowrap;
  }
  .navOptionSelectedStyle {
    font-size: 24px;
    line-height: 28px;
    white-space: nowrap;
  }
  .navOptionHoverContainerStyle:hover .navOptionHoverArrImgWrapperStyle {
    width: 32px;
    height: 32px;
  }
}

@media screen and (max-width: 485px) {
  /* navbar without modal styles start  */
  .navMainContainerStyle {
    padding: 0 24px;
  }
  .navBlueMainContainerStyle {
    padding: 0 24px;
  }
  .navMainWhiteLogoContainerStyle {
    padding: 0 24px;
  }
  /* .navMenuTextWhiteStyle {
    display: none;
  }
  .navMenuTextBlueStyle {
    display: none;
  } */
  /* navbar without modal styles end  */
  /* navbar modal styles start  */

  /* .navBarMenuContainerStyle {
    height: 100%;
  }
  .navBarMenuCloseContainerStyle {
    height: 100%;
  } */
  .navOptionsMainContainerStyle {
    padding: 0 24px;
  }
  .navBarOptionsContainerStyle {
    padding: 0 24px;
  }
  .navBarOptionsWrapperStyle {
    gap: 4px;
  }
  .navBarOptionTextStyle {
    font-size: 28px;
    line-height: 32px;
  }
  .navOptionSelectedStyle {
    font-size: 28px;
    line-height: 32px;
  }
  .navOptionHoverContainerStyle:hover .navOptionHoverArrImgWrapperStyle {
    width: 32px;
    height: 32px;
  }
  .navRightContainerStyle {
    gap: 8px;
  }
  /* navbar modal styles end  */
}
@media screen and (max-width: 360px) {
  /* navbar modal styles start  */
  .navBarOptionTextStyle {
    font-size: 24px;
    line-height: 28px;
  }
  .navBarOptionTextStyle {
    font-size: 24px;
    line-height: 28px;
  }
  .navBarPolicyContainerStyle {
    gap: 2px;
  }
  .navBarPolicyTextStyle {
    font-size: 14px;
    line-height: 22px;
  }
  .navBarOptionsLanguageContainerStyle {
    gap: 5px;
  }
  .languageTextStyle {
    font-size: 14px;
    line-height: 22px;
  }
  .languageSelectedTextStyle {
    font-size: 14px;
    line-height: 22px;
  }
  .navOptionHoverContainerStyle:hover .navOptionHoverArrImgWrapperStyle {
    width: 28px;
    height: 28px;
  }
  /* navbar modal styles end  */
}
