.ourStoryPageFocusContainerStyle {
  opacity: 1;
  transition: 0.15s ease-in-out;
}
.ourStoryPageBlurContainerStyle {
  opacity: 0;
  transition: 0.15s ease-in-out;
}
.positonElementStyle {
  position: relative;
}
/* header container styles start  */
.ourStoryPageInsideContainerStyle {
  max-width: 1440px;
  margin: auto;
  width: 100%;
  padding: 0px 64px 0px 64px;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0px;
}
.headerContainerStyle {
  max-width: 720px;
  margin: auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  justify-content: center;
}
.headerTextStyle {
  font-family: "NeueHassDisplay-SemiBold", sans-serif;
  font-size: 128px;
  line-height: 132px;
  color: var(--blue-primary);
  text-align: center;
}
.buttonStyle:hover {
  background-color: var(--orange-light);
}
/* header container styles end  */
/* second container styles start  */
.secondMainContainerStyle {
  background-color: var(--secondary-EFF1F4);
  border-radius: 16px 16px 0px 0px;
  position: sticky;
  top: 0px;
  z-index: 2;
}
.secondInsideContainerStyle {
  max-width: 1440px;
  margin: auto;
  width: 100%;
  padding: 0px 64px 0px 64px;
  display: flex;
  flex-direction: column;
}
.secondContainerStyle {
  max-width: 720px;
  margin: auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.contentContainerStyle {
  display: grid;
  grid-template-columns: auto auto;
  gap: 16px;
}
.contentHeaderTextStyle {
  font-family: "NeueHassDisplay-SemiBold", sans-serif;
  font-size: 32px;
  line-height: 36px;
  color: var(--blue-primary);
}
.contentParaTextStyle {
  font-family: "Manrope-Regular", sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: var(--blue-opacity-80);
}
/* second container styles end  */
/* third container styles start  */
.thirdMainContainerStyle {
  background-color: var(--blue-primary);
  border-radius: 16px 16px 0px 0px;
  position: sticky;
  top: 0px;
  z-index: 3;
}
.thirdInsideContainerStyle {
  max-width: 1440px;
  margin: auto;
  width: 100%;
  padding: 0px 64px 0px 64px;
  display: flex;
  flex-direction: column;
}
.thirdContainerStyle {
  max-width: 1312px;
  margin: auto;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.thirdTextStyle {
  font-family: "NeueHassDisplay-SemiBold", sans-serif;
  font-size: 64px;
  line-height: 68px;
  color: var(--white);
}
/* third container styles end  */
/* fourth container styles start */
.fourthMainContainerStyle {
  background-color: var(--secondary-EFF1F4);
  border-radius: 16px 16px 0px 0px;
  position: sticky;
  top: 0px;
  z-index: 4;
}
.fourthInsideContainerStyle {
  max-width: 1440px;
  margin: auto;
  width: 100%;
  padding: 0px 64px 0px 64px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.fourthContainerStyle {
  width: 100%;

  display: flex;
  flex-direction: row;
  gap: 64px;
  overflow-x: scroll;
  overflow-y: hidden;
  transform: rotateX(180deg);
}
.fourthContainerStyle::-webkit-scrollbar {
  display: block;
  height: 4px;
  background-color: var(--blue-opacity-20);
  border-radius: 2px;
}
.fourthContainerStyle::-webkit-scrollbar-thumb {
  background-color: var(--orange-dark);
  border-radius: 2px;
}

.cardWrapperStyle {
  min-width: 720px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  transform: rotateX(180deg);
  padding-top: 100px;
}
.contentTextwrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.contentOrangeTextStyle {
  font-family: "Manrope-Medium", sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: var(--orange-dark);
}
.arrowBtnContainerStyle {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}
.arrowWrapperStyle {
  width: 48px;
  height: 48px;
  cursor: pointer;
}
.nextArrowStyle {
  stroke: var(--black);
}
.BackArrowStyle {
  stroke: var(--arrow-disable);
}
/* fourth container styles end  */
@media screen and (max-width: 1440px) {
  /* first container styles start  */
  .headerTextStyle {
    font-size: 100px;
    line-height: 104px;
  }
  /* first container styles end  */
  /* second container styles start  */
  .contentParaTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
  /* second container styles end  */
  /* fourth container styles start */
  .contentOrangeTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
  /* fourth container styles end  */
}
@media screen and (max-width: 1280px) {
  /* first container styles start  */
  .headerContainerStyle {
    max-width: 580px;
  }
  .headerTextStyle {
    font-size: 80px;
    line-height: 84px;
  }
  /* first container styles end  */
  /* third container styles start  */
  .thirdTextStyle {
    font-size: 56px;
    line-height: 60px;
  }
  /* third container styles end  */
}

@media screen and (max-width: 1080px) {
  /* first container styles start  */
  .headerContainerStyle {
    max-width: 480px;
  }
  .headerTextStyle {
    font-size: 64px;
    line-height: 68px;
  }
  /* first container styles end  */
  /* second container styles start  */
  .contentHeaderTextStyle {
    font-size: 28px;
    line-height: 32px;
  }
  /* second container styles end  */
  /* third container styles start  */
  .thirdTextStyle {
    font-size: 48px;
    line-height: 52px;
  }
  /* third container styles end  */
  /* fourth container styles start  */
  .cardWrapperStyle {
    padding-top: 80px;
  }
  /* fourth container styles end  */
}
@media screen and (max-width: 867px) {
  /* first container styles start  */
  .ourStoryPageInsideContainerStyle {
    padding: 0px 32px 0px 32px;
  }
  .headerContainerStyle {
    max-width: 380px;
  }
  .headerTextStyle {
    font-size: 56px;
    line-height: 60px;
  }
  /* first container styles end  */
  /* second container styles start  */
  .secondInsideContainerStyle {
    padding: 0px 32px 0px 32px;
  }
  .contentContainerStyle {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .contentHeaderTextStyle {
    font-size: 24px;
    line-height: 28px;
  }
  /* second container styles end  */
  /* third container styles start  */
  .thirdInsideContainerStyle {
    padding: 0px 32px 0px 32px;
  }
  .thirdTextStyle {
    font-size: 40px;
    line-height: 44px;
    text-align: center;
  }
  /* third container styles end  */
  /* fourth container styles start */

  .fourthInsideContainerStyle {
    padding: 0px 32px 0px 32px;
  }
  .fourthContainerStyle {
    flex-direction: column;
    gap: 48px;
    height: 552px;
    overflow-y: scroll;
    transform: rotateY(180deg);
  }
  .fourthContainerStyle::-webkit-scrollbar:horizontal {
    display: none;
  }
  .fourthContainerStyle::-webkit-scrollbar {
    width: 4px;
  }
  .cardWrapperStyle {
    min-width: 100%;
    padding-left: 16px;
    transform: rotateY(180deg);
    padding-top: 0px;
  }
  .arrowBtnContainerStyle {
    display: none;
  }
  /* fourth container styles end*/
}
@media screen and (max-width: 485px) {
  /* first container styles start  */
  .ourStoryPageInsideContainerStyle {
    padding: 0px 24px 0px 24px;
  }
  .headerContainerStyle {
    max-width: 280px;
    align-items: flex-start;
    margin: unset;
  }
  .headerTextStyle {
    font-size: 48px;
    line-height: 52px;
    text-align: left;
  }
  /* first container styles end  */
  /* second container styles start  */
  .secondInsideContainerStyle {
    padding: 0px 24px 0px 24px;
  }
  /* second container styles end  */
  /* third container styles start  */
  .thirdInsideContainerStyle {
    padding: 0px 24px 0px 24px;
  }
  .thirdTextStyle {
    font-size: 36px;
    line-height: 40px;
    text-align: left;
  }
  /* third container styles end  */
  /* fourth container styles start */

  .fourthInsideContainerStyle {
    padding: 0px 24px 0px 24px;
  }
  .fourthContainerStyle {
    gap: 32px;
  }
  /* fourth container styles end*/
}
@media screen and (max-width: 360px) {
  /* first container styles start  */
  .headerContainerStyle {
    gap: 24px;
  }
  .headerTextStyle {
    font-size: 40px;
    line-height: 44px;
  }
  /* first container styles end  */
  /* second container styles start  */
  .contentHeaderTextStyle {
    font-size: 20px;
    line-height: 24px;
  }
  /* second container styles end  */
  /* third container styles start */
  .thirdTextStyle {
    font-size: 28px;
    line-height: 32px;
  }
  /* third container styles end  */
}
