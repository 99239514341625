.mainContainerStyle {
  max-width: 1440px;
  margin: auto;
  padding: 64px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.imageContainerStyle {
  display: flex;
  flex-direction: row;
  gap: 24px;
  width: 100%;
  align-items: center;
}
.imageWrapperStyle {
  max-width: 700px;
  width: 100%;
  height: 400px;
  border-radius: 16px;
  overflow: hidden;
}
.imgStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.insideContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.titleContainerStyle{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.inputContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.checkContainerStyle {
  display: flex;
  flex-direction: row;
  gap: 16px;
}
.listStyle {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}
.inputStyle {
  border: none;
}
.buttonStyle {
  width: fit-content;
}
.errorTextStyle {
  color: red;
}
.successTextStyle {
  color: green;
}
.closeIconStyle {
  min-width: 24px;
}
.inputInsideContainerStyle {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  width: 100%;
}
.inputStyle {
  width: 24px;
  height: 24px;
}
.pdfContainerStyle {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
