.whistlePolicyPageFocusContainerStyle {
  opacity: 1;
  transition: 0.15s ease-in-out;
}
.whistlePolicyPageBlurContainerStyle {
  opacity: 0;
  transition: 0.15s ease-in-out;
}

.pageInsideContainerStyle {
  max-width: 1440px;
  margin: auto;
  width: 100%;
  padding: 128px 64px 100px 64px;
  display: flex;
  flex-direction: column;
  gap: 100px;
}
.itemContainerStyle {
  display: grid;
  grid-template-columns: 48% 52%;
  justify-content: space-between;
}
.headerTextStyle {
  font-family: "NeueHassDisplay-SemiBold", sans-serif;
  font-size: 56px;
  line-height: 60px;
  color: var(--black);
}
.subTitleTextStyle {
  font-family: "NeueHassDisplay-SemiBold", sans-serif;
  font-size: 24px;
  line-height: 28px;
  color: var(--black);
}
.contentContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.contentTextStyle {
  font-family: "Manrope-Regular", sans-serif;
  line-height: 28px;
  font-size: 18px;
  color: var(--black-opacity-80);
  list-style-type: none;
  
}
.contentOrangeTextstyle {
  font-family: "Manrope-Medium", sans-serif;
  line-height: 28px;
  font-size: 18px;
  color: var(--orange-dark);
}
.contentBlueTextstyle {
  font-family: "Manrope-Medium", sans-serif;
  line-height: 28px;
  font-size: 18px;
  color: var(--sky-Blue);
  text-decoration: underline;
  word-break: keep-all;
  cursor: pointer;
}
@media screen and (max-width: 1440px) {
  .contentTextStyle {
    line-height: 24px;
    font-size: 16px;
  }
  .contentOrangeTextstyle {
    line-height: 24px;
    font-size: 16px;
  }
  .contentBlueTextstyle {
    line-height: 24px;
    font-size: 16px;
  }
}
@media screen and (max-width: 1080px) {
  .pageInsideContainerStyle {
    padding: 104px 74px 80px 74px;
    gap: 80px;
  }
  .itemContainerStyle {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .headerTextStyle {
    font-size: 48px;
    line-height: 52px;
  }
}
@media screen and (max-width: 867px) {
  .pageInsideContainerStyle {
    padding: 104px 32px 64px 32px;
    gap: 64px;
  }
}
@media screen and (max-width: 485px) {
  .pageInsideContainerStyle {
    padding: 96px 24px 48px 24px;
    gap: 48px;
  }
  .headerTextStyle {
    font-size: 40px;
    line-height: 44px;
  }
}
