.imageContainerStyle {
  display: flex;
  flex-direction: row;
  gap: 24px;
  width: 100%;
  align-items: center;
}
.imageWrapperStyle {
  max-width: 700px;
  width: 100%;
  height: 400px;
  border-radius: 16px;
  overflow: hidden;
}
.imgStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.inputContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}
.inputStyle {
  border: none;
}
.successTextStyle {
  color: green;
}
.errorTextStyle {
  color: red;
}
