.dashBoardMainContainerStyle {
  padding: 80px;
}
.dashBoardContainerStyle {
  display: grid;
  grid-template-columns: auto auto;
  gap: 32px;
}
.buttonStyles {
  height: 150px;
  font-size: 24px;
  line-height: 30px;
  align-items: center;
  justify-content: center;
  background-color: var(--orange-light);
  cursor: pointer;
  transition: 0.5s ease-in-out;
}
.buttonStyles:hover {
  background-color: var(--orange-disable);
  transform: scale(1.02);
  transition: 0.5s ease-in-out;
}
@media screen and (max-width: 986px) {
  .dashBoardMainContainerStyle {
    padding: 64px;
  }
}
@media screen and (max-width: 720px) {
  .dashBoardMainContainerStyle {
    padding: 48px;
  }
  .dashBoardContainerStyle {
    gap: 24px;
  }
  .buttonStyles {
    height: 100px;
    font-size: 20px;
    line-height: 24px;
  }
}
@media screen and (max-width: 485px) {
  .dashBoardMainContainerStyle {
    padding: 24px;
  }
  .dashBoardContainerStyle {
    grid-template-columns: auto;
    gap: 16px;
  }
  .buttonStyles {
    height: 80px;
    font-size: 18px;
    line-height: 24px;
  }
}
