.newsPageFocusContainerStyle {
  opacity: 1;
  transition: 0.15s ease-in-out;
}
.newsPageBlurContainerStyle {
  opacity: 0;
  transition: 0.15s ease-in-out;
}

.newsPageInsideContainerStyle {
  max-width: 1440px;
  margin: auto;
  width: 100%;
  padding: 128px 64px 100px 64px;
  display: flex;
  flex-direction: column;
  gap: 100px;
}
.newsPageTopContainerStyle {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 64px;
}
.headerContainerStyle {
  max-width: 720px;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.headerTextStyle {
  font-family: "NeueHassDisplay-SemiBold", sans-serif;
  font-size: 56px;
  line-height: 60px;
  color: var(--black);
}
.postsContainerStyle {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: baseline;
  cursor: pointer;
}
.allPostsTextStyle {
  font-family: "Manrope-Medium", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: var(--orange-dark);
}
.arrowImgWrapperStyle {
  width: 12px;
  height: 12px;
}
.imgStyle {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.newsPageTopCarousalContainerStyle {
  border-top: 1px solid var(--black-opacity-20);
  padding-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.refContainerStyle {
  max-width: 720px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}
.cardOuterContainerStyle {
  max-width: 720px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  cursor: pointer;
  text-decoration: none;
  width: 100%;
}

.cardDateTextStyle {
  font-family: "Manrope-Medium", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: var(--black-opacity-50);
}
.newsImgWrapperStyle {
  position: relative;
  width: 720px;
  max-width: 100%;
  height: 460px;
  border-radius: 16px;
  overflow: hidden;
}
.imgNewsStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.imgNews2Style {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}
.imageBackgoundHoverStyle {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  transition: 0.4s ease-in-out;
}
.imgHoverStyle {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  width: 48px;
  height: 48px;
  transition: 0.4s ease-in-out;
}
.cardOuterContainerStyle:hover .imageBackgoundHoverStyle {
  background-color: var(--transparent-blue);
  transition: 0.4s ease-in-out;
}

.cardOuterContainerStyle:hover .imgHoverStyle {
  opacity: 1;
  transition: 0.4s ease-in-out;
}
.cardDescContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.cardDescHeaderTextStyle {
  font-family: "NeueHassDisplay-SemiBold", sans-serif;
  font-size: 40px;
  line-height: 44px;
  color: var(--black);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: keep-all;
}
.cardDescTextStyle {
  font-family: "Manrope-Regular", sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: var(--black-opacity-50);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.buttonsContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.nextButtonContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.arrowButtonStyle {
  cursor: pointer;
}
.arrowBtnStyle {
  stroke: var(--black);
}

.cardsGridContainerStyle {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  column-gap: 64px;
  row-gap: 100px;
  border-top: 1px solid var(--black-opacity-20);
  padding-top: 32px;
}
.linkStyle {
  text-decoration: none;
}
.buttonStyle {
  border: 1px solid var(--black-opacity-20);
  border-radius: 80px;
  background-color: var(--white);
  color: var(--black);
  margin: auto;
  transition: 0.4s ease-in-out;
  cursor: pointer;
}
.buttonStyle:hover {
  border: 1px solid transparent;
  background-color: var(--secondary-EFF1F4);
  transition: 0.4s ease-in-out;
}
/* popover Styles  */
.popoverContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
}
.popoverLangContainerStyle {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  cursor: pointer;
}
.imageWrapperStyle {
  width: 20px;
  height: 20px;
}
.popoverTextSTyle {
  font-family: "Manrope-Medium", sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: var(--black-opacity-50);
}
.popOverSelectTextStyle {
  font-family: "Manrope-Medium", sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: var(--black);
}
/* popover styles end  */
@media screen and (max-width: 1440px) {
  .cardDescTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .popoverTextSTyle {
    font-size: 16px;
    line-height: 24px;
  }
  .popOverSelectTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
}
@media screen and (max-width: 1280px) {
  .cardsGridContainerStyle {
    column-gap: 32px;
  }
}
@media screen and (max-width: 1080px) {
  .newsPageInsideContainerStyle {
    padding: 104px 64px 80px 64px;
    gap: 80px;
  }
  .newsPageTopContainerStyle {
    gap: 48px;
  }
  .headerTextStyle {
    font-size: 48px;
    line-height: 52px;
  }

  .newsPageTopCarousalContainerStyle {
    flex-direction: column;
    gap: 32px;
  }
  .refContainerStyle {
    max-width: 620px;
    display: flex;
    flex-direction: row;
    overflow: hidden;
  }
  .cardDescHeaderTextStyle {
    font-size: 32px;
    line-height: 36px;
  }
  .buttonsContainerStyle {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }
  .nextButtonContainerStyle {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
  .cardsGridContainerStyle {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    row-gap: 80px;
  }
}
@media screen and (max-width: 867px) {
  .newsPageInsideContainerStyle {
    padding: 104px 32px 64px 32px;
    gap: 64px;
  }
  .newsPageTopContainerStyle {
    gap: 32px;
  }
  .headerContainerStyle {
    flex-direction: column-reverse;
    gap: 8px;
    align-items: flex-start;
  }
  .newsImgWrapperStyle {
    height: 400px;
  }
  .cardDescContainerStyle {
    gap: 12px;
  }
  .cardsGridContainerStyle {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 16px;
  }
}
@media screen and (max-width: 485px) {
  .newsPageInsideContainerStyle {
    padding: 96px 24px 48px 24px;
    gap: 48px;
  }
  .cardOuterContainerStyle {
    gap: 12px;
  }
  .headerTextStyle {
    font-size: 40px;
    line-height: 44px;
  }
  .cardDateTextStyle {
    font-size: 14px;
    line-height: 20px;
  }
  .newsImgWrapperStyle {
    height: 280px;
  }
  .cardDescContainerStyle {
    gap: 8px;
  }
  .cardDescHeaderTextStyle {
    font-size: 28px;
    line-height: 32px;
  }
  .buttonStyle {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
}
