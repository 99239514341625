/* * {
  transition: all 0.6s;
} */

.notFoundMainContainerStyles {
    display: table;
    width: 100%;
    height: 100vh;
    text-align: center;
  }
  
  .errorMessageContainerStyles {
    display: table-cell;
    vertical-align: middle;
  }
  
  .errorMessageTextStyles {
    font-size: 50px;
    display: inline-block;
    padding-right: 12px;
    animation: type 0.5s alternate infinite;
    color: #d16c1d;
  }
  .homeTextStyles {
    text-decoration: underline;
    cursor: pointer;
    color: #d16c1d;
  }
  
  @keyframes type {
    from {
      box-shadow: inset -3px 0px 0px #d16c1d;;
    }
    to {
      box-shadow: inset -3px 0px 0px transparent;
    }
  }
  