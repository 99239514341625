.popupOutContainerStyle{
    /* position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    top: 100%;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color:transparent;
    padding: 0 24px;
    width: 100%;
    height: 100%;
    max-height:200% ; */
    /* border: none; */
}

.popupContainerStyle{
    max-width: 496px;
    width: 100%;
    padding: 24px 32px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    background-color: var(--white);
    border-radius: 16px;
    border: 1px solid #C4C4C4;
    box-shadow: 0px 48px 96px 0px rgba(0, 0, 0, 0.08);
  
}
.msgTextStyle{
    font-family: "NeueHassDisplay-Medium",sans-serif;
    font-weight: 500;
    font-size: 28px;
    line-height: 32px;
    color: black;
}
.thankYouTextStyle{
    color:var(--orange-dark);
}
.btnStyle{
    background-color: var(--secondary-EEEEEE);
    color: var(--black);
    width: fit-content;
}