.leftArrowWrapperStyle {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  width: fit-content;
}
.arrowBtnStyle {
  stroke: var(--black);
}
.backArrowStyle {
  width: 30px;
  height: 30px;
}
