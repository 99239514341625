.imgStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.dashBoardContainerStyle {
  display: flex;
  flex-direction: column;
}
.navMainContainerStyle {
  padding: 24px 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.logoImgWrapperStyle {
  width: 155px;
}
.navRightContainerStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.optionBtnWrapperStyle {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.svgColorStyle {
  fill: var(--blue-primary);
}
.modalContainerStyles {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
  padding: 24px;
}
.menuTextStyle {
  font-family: "Manrope Medium", sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: var(--black);
  cursor: pointer;
}
/* popover Styles  */
.popoverContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
}
.popoverLangContainerStyle {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
}
.imageWrapperStyle {
  width: 20px;
  height: 20px;
}
.popoverTextSTyle {
  font-family: "Manrope-Medium", sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: var(--black-opacity-50);
}
.popOverSelectTextStyle {
  font-family: "Manrope-Medium", sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: var(--black);
}
/* popover styles end  */
@media screen and (max-width: 485px) {
  .navMainContainerStyle {
    padding: 16px 24px;
  }
}
