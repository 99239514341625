.ellipsesText {
  max-width: 60px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.containerStyle {
  height: calc(100vh - 80px);
}
.containerWrapperStyle {
  padding: 0 20px 20px;
}
.jobpostingHeaderTextStyle {
  text-align: center;
}
