.mainContainerStyle {
  position: relative;
  padding: 0 20px 20px;
}
.modalContainerStyle {
  position: absolute;
  top: -78px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 6;
  height: fit-content;
}
.containerStyle {
  height: calc(100vh - 80px);
}
.headerTextStyle {
  text-align: center;
}
