.pageFocusContainerStyle {
  opacity: 1;
  transition: 0.15s ease-in-out;
}
.pageBlurContainerStyle {
  opacity: 0;
  transition: 0.15s ease-in-out;
}
.viewInsideContainerStyle {
  max-width: 1440px;
  margin: auto;
  width: 100%;
  padding: 128px 64px 100px 64px;
  display: flex;
  flex-direction: column;
  gap: 100px;
}
.contentContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 64px;
}
.headerTextSTyle {
  max-width: 720px;
  margin: auto;
  font-family: "NeueHassDisplay-SemiBold", sans-serif;
  font-size: 56px;
  line-height: 60px;
  color: var(--black);
}
.linkStyle {
  text-decoration: none;
}
.imageWrapperStyle {
  max-width: 1312px;
  width: 100%;
  max-height: 720px;
  height: 100%;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
    justify-content: center;
    object-fit: contain;
}
.postContentStyle {
  max-width: 720px;
  margin: auto;
  border-left: 1px solid var(--blue-opacity-20);
  padding-left: 32px;
}
.imgStyle {
  /* width: 100%;
  height: 100%;
  object-fit: cover; */
  width: fit-content;
  height: auto;
  object-fit: contain;
  border-radius: 16px;
}
.cardsGridContainerStyle {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  column-gap: 64px;
  row-gap: 100px;
  border-top: 1px solid var(--black-opacity-20);
  padding-top: 32px;
}
.textStyle {
  font-family: "Manrope-Regular", sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: var(--black-opacity-80);
}
.linkTextStyle {
  font-family: "Manrope-Regular", sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: var(--orange-dark);
}
@media screen and (max-width: 1440px) {
  .textStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .linkTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
}
@media screen and (max-width: 1280px) {
  .imageWrapperStyle {
    max-height: 568px;
  }
  .cardsGridContainerStyle {
    column-gap: 32px;
  }
}
@media screen and (max-width: 1080px) {
  .viewInsideContainerStyle {
    padding: 104px 64px 80px 64px;
    gap: 80px;
  }
  .headerTextSTyle {
    font-size: 48px;
    line-height: 52px;
  }
  .imageWrapperStyle {
    max-height: 468px;
  }
  .cardsGridContainerStyle {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    row-gap: 80px;
  }
}
@media screen and (max-width: 867px) {
  .viewInsideContainerStyle {
    padding: 104px 32px 64px 32px;
    gap: 64px;
  }
  .contentContainerStyle {
    gap: 48px;
  }
  .imageWrapperStyle {
    max-height: 360px;
  }
  .postContentStyle {
    padding-left: 24px;
  }
  .cardsGridContainerStyle {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 16px;
  }
}
@media screen and (max-width: 485px) {
  .viewInsideContainerStyle {
    padding: 96px 24px 48px 24px;
    gap: 48px;
  }
  .contentContainerStyle {
    gap: 32px;
  }
  .headerTextSTyle {
    font-size: 40px;
    line-height: 44px;
  }
  .imageWrapperStyle {
    max-height: 268px;
  }
}
@media screen and (max-width: 360px) {
  .headerTextSTyle {
    font-size: 32px;
    line-height: 36px;
  }
  .imageWrapperStyle {
    max-height: 196px;
  }
  .postContentStyle {
    padding-left: 16px;
  }
}
