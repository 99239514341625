.buttonStyle {
  font-family: "Manrope-Medium", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: var(--white);
  background-color: var(--orange-dark);
  border-radius: 26px;
  padding: 12px 32px;
  border: none;
  display: flex;
  flex-direction: row;
  gap: 2px;
  transition: all 0.5s ease;
  cursor: pointer;
}
.imgStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
