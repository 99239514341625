.pageContainerStyle {
  max-width: 1440px;
  margin: auto;
  width: 100%;
  padding: 64px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.teamContainerStyle {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 24px;
}
.gridContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.inputContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.imageInputStyle {
  border: none;
  width: 250px;
}
.buttonStyle {
  width: fit-content;
}
.listStyle {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}
.errorMessageStyle {
  color: red;
}
.successMessageStyle {
  color: green;
}
.closeIconStyle {
  min-width: 24px;
}
.inputInsideContainerStyle {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  width: 100%;
}
.inputInsideLangContainerStyle{
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  width: 100%;
}
.labelContainerStyle{
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}
.designationContainerStyle{
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;

}
.langContainerStyle{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
}
.imageContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.imageWrapperStyle {
  width: 316px;
  height: 360px;
}
.containerStyle {
  height: calc(100vh - 80px);
}
