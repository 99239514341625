.cardOuterContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
  cursor: pointer;
}
.cardDateTextStyle {
  font-family: "Manrope-Medium", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: var(--black-opacity-50);
}
.cardDateMobileTextStyle {
  display: none;
}
.cardDateText2Style {
  display: none;
  font-family: "Manrope-Medium", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: var(--black-opacity-50);
}
.newsImgWrapperStyle {
  position: relative;
  width: 100%;
  max-width: 396px;
  height: 320px;
  border-radius: 16px;
  overflow: hidden;
}
.imgNewsStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.imageBackgoundHoverStyle {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  transition: 0.4s ease-in-out;
}
.imgHoverStyle {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  width: 48px;
  height: 48px;
  transition: 0.4s ease-in-out;
}
.cardOuterContainerStyle:hover .imageBackgoundHoverStyle {
  background-color: var(--transparent-blue);
  transition: 0.4s ease-in-out;
}

.cardOuterContainerStyle:hover .imgHoverStyle {
  opacity: 1;
  transition: 0.4s ease-in-out;
}
.cardDescContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.cardDescHeaderTextStyle {
  font-family: "NeueHassDisplay-SemiBold", sans-serif;
  font-size: 24px;
  line-height: 28px;
  color: var(--black);
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: keep-all;
}
.cardDescTextStyle {
  font-family: "Manrope-Regular", sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: var(--black-opacity-50);
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media screen and (max-width: 867px) {
  .cardOuterContainerStyle {
    flex-direction: row;
    gap: 24px;
  }
  .cardDateTextStyle {
    display: none;
  }
  .cardDateMobileTextStyle {
    display: block;
    font-family: "Manrope-Medium", sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: var(--black-opacity-50);
  }
  .cardDateText2Style {
    display: block;
    font-size: 14px;
    line-height: 20px;
  }
  .cardDescHeaderTextStyle {
    -webkit-line-clamp: 3;
  }
  .newsImgWrapperStyle {
    width: 160px;
    min-width: 160px;
    height: 160px;
    border-radius: 8px;
  }

  .cardDescContainerStyle {
    gap: 4px;
  }
  .cardDescTextStyle {
    display: none;
  }
}
@media screen and (max-width: 485px) {
  .cardOuterContainerStyle {
    gap: 16px;
  }
  .newsImgWrapperStyle {
    width: 80px;
    min-width: 80px;
    height: 80px;
    border-radius: 8px;
  }
  .cardDescHeaderTextStyle {
    -webkit-line-clamp: 2;
    font-size: 20px;
    line-height: 24px;
  }
}
@media screen and (max-width: 485px) {
  .cardDescHeaderTextStyle {
    font-size: 16px;
    line-height: 20px;
  }
}
