.aboutPageFocusContainerStyle {
  opacity: 1;
  transition: 0.15s ease-in-out;
}
.aboutPageBlurContainerStyle {
  opacity: 0;
  transition: 0.15s ease-in-out;
}
.aboutPageInsideContainerStyle {
  max-width: 1440px;
  margin: auto;
  width: 100%;
  padding: 128px 64px 100px 64px;
  display: flex;
  flex-direction: column;
  gap: 100px;
}
.aboutPagetopSectionStyle {
  display: flex;
  flex-direction: column;
  gap: 64px;
}
.aboutHeaderContainerStyle {
  max-width: 720px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.aboutHeaderTextStyle {
  font-family: "NeueHassDisplay-SemiBold", sans-serif;
  font-size: 56px;
  line-height: 60px;
  color: var(--black);
}
.aboutHeaderTextOrangeStyle {
  font-family: "NeueHassDisplay-SemiBold", sans-serif;
  font-size: 56px;
  line-height: 60px;
  color: var(--orange-dark);
}
.aboutContentMainContainerStyle {
  display: grid;
  grid-template-columns: auto auto;
  gap: 32px;
}
.aboutContentWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.aboutContentHeaderTextStyle {
  font-family: "Manrope-Medium", sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: var(--black);
}
.aboutContentTextStyle {
  font-family: "Manrope-Regular", sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: var(--black-opacity-80);
}
.heroImageWrapperStyle {
  width: 100%;
  height: 720px;
}
.heroImgStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
}
.imgStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.aboutMiddleContentContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 100px;
}
.aboutContentOuterMainContainerStyle {
  display: grid;
  grid-template-columns: 60% 40%;
}
.arrBtnStyle {
  display: none;
}
.buttonStyle:hover {
  background-color: var(--orange-light);
  cursor: pointer;
}
.aboutButtonContainerStyle {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.aboutBottomLinkContainerStyle {
  max-width: 895px;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 64px;
}
.aboutBottomLinkItemWrapperStyle {
  display: flex;
  flex-direction: row;
  gap: 2px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.arrowOrangeWrapperStyle {
  width: 24px;
  height: 24px;
}
.orangeTextColorStyle {
  font-family: "Manrope-Medium", sans-serif;
  font-size: 18px;
  line-height: 26px;
  color: var(--orange-dark);
  white-space: nowrap;
}
.aboutTestimonialContainerStyle {
  border-top: 1px solid var(--black-opacity-20);
  display: grid;
  grid-template-columns: 40% 60%;
  padding-top: 16px;
}
.testimonialContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 60px;
}
.aboutTestimonialWrapperStytle {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.aboutTestimonialHeaderStyle {
  font-family: "Manrope-Medium", sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: var(--black);
}
.aboutTestimonialTextStyle {
  font-family: "Manrope-Medium", sans-serif;
  font-size: 24px;
  line-height: 32px;
  color: var(--black);
  white-space: wrap;
  word-wrap: break-word;
}
.arrowBtnContainerStyle {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}
.arrowWrapperStyle {
  width: 48px;
  height: 48px;
  cursor: pointer;
}
.nextArrowStyle {
  stroke: var(--black);
}
.BackArrowStyle {
  stroke: var(--arrow-disable);
}
@media screen and (max-width: 1440px) {
  .aboutContentTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .orangeTextColorStyle {
    font-size: 16px;
    line-height: 24px;
  }
}
@media screen and (max-width: 1080px) {
  .aboutPageInsideContainerStyle {
    padding: 104px 64px 80px 64px;
    gap: 80px;
  }
  .aboutPagetopSectionStyle {
    gap: 48px;
  }
  .aboutHeaderTextStyle {
    font-size: 48px;
    line-height: 52px;
  }
  .aboutHeaderTextOrangeStyle {
    font-size: 48px;
    line-height: 52px;
  }
  .heroImageWrapperStyle {
    height: 468px;
  }
  .aboutMiddleContentContainerStyle {
    gap: 48px;
  }
  .aboutContentOuterMainContainerStyle {
    grid-template-columns: auto;
    gap: 48px;
  }
  .aboutButtonContainerStyle {
    align-items: center;
    justify-content: center;
  }
  .aboutBottomLinkContainerStyle {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
  }
  .aboutTestimonialContainerStyle {
    grid-template-columns: auto;
    gap: 32px;
  }
  .testimonialContainerStyle {
    gap: 48px;
  }
}
@media screen and (max-width: 867px) {
  .aboutPageInsideContainerStyle {
    padding: 104px 32px 64px 32px;
    gap: 64px;
  }
  .aboutContentMainContainerStyle {
    display: grid;
    grid-template-columns: auto;
    gap: 24px;
  }
  .heroImageWrapperStyle {
    height: 428px;
  }
}
@media screen and (max-width: 485px) {
  .aboutPageInsideContainerStyle {
    padding: 96px 24px 48px 24px;
    gap: 48px;
  }
  .aboutPagetopSectionStyle {
    gap: 32px;
  }
  .aboutHeaderContainerStyle {
    gap: 24px;
  }
  .aboutHeaderTextStyle {
    font-size: 40px;
    line-height: 44px;
  }
  .aboutHeaderTextOrangeStyle {
    font-size: 40px;
    line-height: 44px;
  }
  .heroImageWrapperStyle {
    height: 360px;
  }
  .aboutMiddleContentContainerStyle {
    gap: 32px;
  }
  .aboutContentOuterMainContainerStyle {
    gap: 32px;
  }
  .buttonStyle {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .testimonialContainerStyle {
    gap: 32px;
  }
  .aboutTestimonialWrapperStytle {
    gap: 24px;
  }
  .aboutTestimonialTextStyle {
    font-size: 20px;
    line-height: 30px;
  }
}
@media screen and (max-width: 360px) {
}
