@font-face {
  font-family: "Manrope-Bold";
  src: url("../src/assets/fonts/Manrope-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "Manrope-SemiBold";
  src: url("../src/assets/fonts/Manrope-SemiBold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "Manrope-Medium";
  src: url("../src/assets/fonts/Manrope-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "Manrope-Regular";
  src: url("../src/assets/fonts/Manrope-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Manrope-Light";
  src: url("../src/assets/fonts/Manrope-Light.ttf");
  font-weight: 300;
}
@font-face {
  font-family: "NeueHassDisplay-Bold";
  src: url("../src/assets/fonts/NeueHaasDisplayBold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "NeueHassDisplay-SemiBold";
  src: url("../src/assets/fonts/NeueHaasDisplayMediu.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "NeueHassDisplay-Medium";
  src: url("../src/assets/fonts/NeueHaasDisplayRoman.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "NeueHassDisplay-Regular";
  src: url("../src/assets/fonts/NeueHaasDisplayThin.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "NeueHassDisplay-Light";
  src: url("../src/assets/fonts/NeueHaasDisplayXThin.ttf");
  font-weight: 300;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
::-webkit-scrollbar {
  display: none;
}
:root {
  --black: #000000;
  --white: #ffffff;
  --blue-hover: #163b67;
  --orange-light: #ee7415;
  --orange-dark: #d16c1d;
  --orange-disable: #d16c1d80;
  --orange-opacity-10: #d16c1d1a;
  --blue-primary: #061d33;
  --blue-secondary: #061d3399;
  --blue-opacity-80: #061d33cc;
  --blue-opacity-20: #061d3333;
  --black-opacity-20: #00000033;
  --black-opacity-50: #00000080;
  --black-opacity-80: #000000cc;
  --white-opacity-20: #ffffff33;
  --white-opacity-50: #ffffff80;
  --white-opacity-80: #ffffffcc;
  --secondary: #c4c4c4;
  --secondary-EEEEEE: #eeeeee;
  --secondary-EFF1F4: #eff1f4;
  --transparent-blue: #061d3380;
  --arrow-disable: #808080;
  --sky-Blue: #0057ff;
  --error: rgb(243, 57, 57);
  --success:green;
}
