.createJobContainerStyle {
  padding: 64px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.buttonStyle {
  width: fit-content;
}
.errorMessageStyle {
  color: red;
}
.successMessageStyle {
  color: green;
}
.closeIconStyle {
  min-width: 24px;
}
.titleContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.inputContainerStyle {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}
.langContainerStyles {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}
.containerStyle {
  height: calc(100vh - 80px);
}
.inputInsideContainerStyle {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  width: 100%;
}

.inputStyle {
  width: 24px;
  height: 24px;
}
.closeIconStyle {
  min-width: 24px;
}
