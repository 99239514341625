.containerStyle {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 32px;
  background-color: var(--secondary-EFF1F4);
  border-radius: 16px;
}
.textStyle {
  font-family: "Manrope-Regular";
  font-size: 18px;
  line-height: 28px;
  color: var(--black);
}
.linkStyle {
  font-family: "Manrope-Medium";
  font-size: 18px;
  line-height: 28px;
  color: var(--orange-dark);
  text-decoration: underline;
  cursor: pointer;
}
@media screen and (max-width: 1440px) {
  .textStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .linkStyle {
    font-size: 16px;
    line-height: 24px;
  }
}
@media screen and (max-width: 867px) {
  .containerStyle {
    padding: 24px;
  }
}
