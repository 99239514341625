.mainContainerStyle {
  max-width: 1440px;
  margin: auto;
  padding: 64px;
  width: 100%;
}
.insideContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 840px;
  margin: auto;
}

.inputContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.buttonStyle {
  width: fit-content;
}
.errorMessageStyle {
  color: red;
}
.successMessageStyle {
  color: green;
}
.closeIconStyle {
  min-width: 24px;
}
.inputInsideContainerStyle {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  width: 100%;
}
