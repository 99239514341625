.containerStyle {
  width: 100%;
  height: 48px;
  flex-direction: row;
  align-items: center;
  display: flex;
  gap: 8px;
  border: 1px solid var(--black-opacity-20);
  border-radius: 8px;
}
.leftcontainer {
  margin-left: 16px;
}
.deviderStyle {
  width: 10px;
  height: 65%;
  border-left: 1px solid var(--black-opacity-20);
}
.inputStyle {
  height: 45px;
  width: 100%;
  font-family: "Manrope-Regular";
  color: var(--black);
  outline: none;
  border: 1px solid transparent;
  border-radius: 8px;
}
.counrtyCodeTextStyle {
  color: var(--black-opacity-80);
  font-family: "Manrope-Regular";
  line-height: 24px;
  white-space: nowrap;
}
.inputNumberStyle {
  border: none;
}
