.sAndsPageFocusContainerStyle {
  opacity: 1;
  transition: 0.15s ease-in-out;
}
.sAndsPageBlurContainerStyle {
  opacity: 0;
  transition: 0.15s ease-in-out;
}

.servicesPageInsideContainerStyle {
  max-width: 1440px;
  margin: auto;
  width: 100%;
  padding: 128px 64px 100px 64px;
  display: flex;
  flex-direction: column;
  gap: 100px;
}
.servicesPageInsideSubContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.headerMainContainerStyle {
  max-width: 720px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.headerTextStyle {
  font-family: "NeueHassDisplay-SemiBold", sans-serif;
  font-size: 56px;
  line-height: 60px;
  color: var(--black);
}
.headerorangeTextStyle {
  color: var(--orange-dark);
}
.headerSubTextStyle {
  font-family: "Manrope-Regular", sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: var(--black-opacity-80);
}
.middleContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.deviderStyle {
  display: flex;
  width: 100%;
  border-top: 1px solid var(--black-opacity-20);
}
.ourServicesContainer {
  display: grid;
  grid-template-columns: 40% 50%;
  justify-content: space-between;
}
.ourServicesTitleTextStyle {
  font-family: "Manrope-Medium", sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: var(--black);
}
.ourServiceSubContainer {
  gap: 8px;
  display: flex;
  flex-direction: column;
}
.eachServiceStyle {
  flex-direction: row;
  display: flex;
  align-items: center;
  transition: 0.25s ease-in-out;
  width: fit-content;
  text-decoration: none;
}
.eachServiceTextStyle {
  font-family: "NeueHassDisplay-SemiBold";
  font-size: 32px;
  line-height: 36px;
  color: var(--black-opacity-50);
}
.arrowImgConatinerStyle {
  width: 36px;
  height: 36px;
  opacity: 0;
  transition: 0.25s ease-in-out;
}
.arrowImgStyle {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.eachServiceStyle:hover {
  transform: translateX(10px);
  transition: 0.25s ease-in-out;
}
.eachServiceStyle:hover .eachServiceTextStyle {
  color: var(--orange-dark);
}
.eachServiceStyle:hover .arrowImgConatinerStyle {
  opacity: 1;
  transition: 0.25s ease-in-out;
}
@media screen and (max-width: 1440px) {
}
@media screen and (max-width: 1280px) {
  .eachServiceTextStyle {
    font-size: 28px;
    line-height: 32px;
  }
}
@media screen and (max-width: 1080px) {
  .servicesPageInsideContainerStyle {
    padding: 104px 64px 104px 64px;
    gap: 80px;
  }
  .headerTextStyle {
    font-size: 48px;
    line-height: 52px;
  }
  .eachServiceTextStyle {
    font-size: 24px;
    line-height: 28px;
  }
}
@media screen and (max-width: 867px) {
  .servicesPageInsideContainerStyle {
    padding: 104px 32px 104px 32px;
    gap: 64px;
  }
  .ourServicesContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .eachServiceTextStyle {
    color: var(--orange-dark);
  }
}
@media screen and (max-width: 485px) {
  .servicesPageInsideContainerStyle {
    padding: 96px 24px 96px 24px;
    gap: 48px;
  }
  .headerTextStyle {
    font-size: 40px;
    line-height: 44px;
  }
  .eachServiceStyle {
    width: 100%;
    border-bottom: 1px solid var(--black-opacity-20);
    padding-bottom: 8px;
  }
  .eachServiceTextStyle {
    font-size: 20px;
    line-height: 24px;
  }
  .deviderStyle {
    display: none;
  }
  .eachServiceStyle:hover {
    transform: none;
    transition: none;
  }
  .eachServiceStyle:hover .eachServiceTextStyle {
    color: var(--orange-dark);
  }
  .eachServiceStyle:hover .arrowImgConatinerStyle {
    opacity: 0;
    transition: none;
  }
}
