.employmentLawPageFocusContainerStyle {
  opacity: 1;
  transition: 0.15s ease-in-out;
}
.employmentLawPageBlurContainerStyle {
  opacity: 0;
  transition: 0.15s ease-in-out;
}
.pageInsideContainerStyle {
  max-width: 1440px;
  margin: auto;
  width: 100%;
  padding: 128px 64px 100px 64px;
  display: flex;
  flex-direction: column;
  gap: 100px;
}
.headerContainerStyle {
  max-width: 720px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.headerTopContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.linkContainerStyle {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: baseline;
  width: fit-content;
  cursor: pointer;
}
.headerLinkListTextSTyle {
  font-family: "Manrope-Medium", sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: var(--orange-dark);
}
.arrowImgWrapperStyle {
  width: 12px;
  height: 12px;
}
.arrImgStyle {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.headerTextStyle {
  font-family: "NeueHassDisplay-SemiBold", sans-serif;
  font-size: 40px;
  line-height: 44px;
  color: var(--black);
}
.headerOrangeTextStyle {
  font-family: "NeueHassDisplay-SemiBold", sans-serif;
  font-size: 40px;
  line-height: 44px;
  color: var(--orange-dark);
}
.contentTextStyle {
  font-family: "Manrope-Regular", sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: var(--black-opacity-80);
}
.middleContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-top: 1px solid var(--black-opacity-20);
  padding-top: 16px;
}
.middleInsideContainerStyle {
  width: 100%;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--black-opacity-20);
}
.itemContainerStyle {
  display: grid;
  grid-template-columns: 50% 50%;
  cursor: pointer;
}
.itemContainerStyle2 {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  gap: 32px;
}
.contentContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-height: 2300px;
  height: 100%;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}
.contentContentOpenStyle {
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-height: 0px;
  height: 0%;
  width: 0%;
  overflow: hidden;
}
.contentOrangeTextStyle {
  font-family: "Manrope-SemiBold", sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: var(--blue-primary);
}
.listStyles {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-left: 20px;
}
.serviceBlockStyle {
  max-width: 720px;
  width: 100%;
  margin: auto;
}
.userContainerStyle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 445px;
}
.userDetailsStyle {
  display: flex;
  flex-direction: column;
}
.userContactLinksTextStyle {
  width: fit-content;
  font-family: "Manrope-Medium";
  font-size: 18px;
  line-height: 28px;
  color: var(--orange-dark);
  text-decoration: underline;
  cursor: pointer;
}
.cardContainerStyle {
  max-width: 320px;
  display: flex;
  width: 100%;
}
.cardStyle {
  width: 100%;
}
.cardStyle:hover .cardArrowStyle {
  display: none;
}
.imgWrapperStyle {
  width: 24px;
  height: 24px;
  justify-self: flex-end;
}
.imgStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* popover Styles  */
.popoverContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
}
.popoverLangContainerStyle {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  cursor: pointer;
}
.imageWrapperStyle {
  width: 20px;
  height: 20px;
}
.popoverTextSTyle {
  font-family: "Manrope-Medium", sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: var(--black-opacity-50);
}
.popOverSelectTextStyle {
  font-family: "Manrope-Medium", sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: var(--black);
}
/* popover styles end  */
@media screen and (max-width: 1440px) {
  .headerLinkListTextSTyle {
    font-size: 16px;
    line-height: 24px;
  }
  .userContactLinksTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .contentTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .contentOrangeTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .popoverTextSTyle {
    font-size: 16px;
    line-height: 24px;
  }
  .popOverSelectTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
}
@media screen and (max-width: 1080px) {
  .pageInsideContainerStyle {
    padding: 104px 64px 80px 64px;
    gap: 80px;
  }
  .itemContainerStyle {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 568px;
    margin-left: auto;
  }
  .contentContainerStyle {
    gap: 8px;
  }
}
@media screen and (max-width: 867px) {
  .pageInsideContainerStyle {
    padding: 104px 32px 64px 32px;
    gap: 64px;
  }
  .userContainerStyle {
    flex-direction: column;
    gap: 32px;
    height: 509px;
  }
}
@media screen and (max-width: 720px) {
  .userContainerStyle {
    flex-direction: column;
    gap: 32px;
    height: fit-content;
  }
}
@media screen and (max-width: 485px) {
  .pageInsideContainerStyle {
    padding: 96px 24px 48px 24px;
    gap: 48px;
  }
  .headerContainerStyle {
    gap: 24px;
  }
  .headerTopContainerStyle {
    gap: 8px;
  }
  .headerTextStyle {
    font-size: 32px;
    line-height: 36px;
  }
  .headerOrangeTextStyle {
    font-size: 32px;
    line-height: 36px;
  }
  .userContainerStyle {
    height: 248px;
  }
}
